<template>
    <div class="mb-5 pb-4">
        <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleCategories"
               v-on:error="setErrors($event.response)"
               v-on:request="trigger=false;"></axios>
        <div class="container-fluid pl-5 pr-5 content-container">

            <div class="row">
                <div class="col-sm-12 bg-light shadow p-3 text-left ">
                    <p class="heading">Food Categories</p>
                    <hr>
                    <!--<div class="text-center">-->
                    <!--<pulse-loader :loading="loading"></pulse-loader>-->
                    <!--</div>-->


                    <im type="categories"></im>

                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{msg}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover  ">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Category Code</th>
                                <th scope="col">Active</th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="categories.length==0">
                                <td colspan="3">No record found</td>
                            </tr>
                            <tr :class="{'table-success':changed_id==category.id}"
                                v-for="(category,i) in categories" :key="i">
                                <th scope="row">{{i + 1}}</th>
                                <td>
                                    <!--<span v-if="current_category!=category.id">{{category.name}}</span>-->
                                    <input type="text" @change="update_category(i)" class="form-control"
                                           v-model="category.name">
                                    <span class="text-danger" v-if="index==i">{{getError('name')}}</span>
                                </td>
                                <td>
                                    <!--<span v-if="current_category!=category.id">{{category.code}}</span>-->
                                    <input type="text" class="form-control" @change="update_category(i)"
                                           v-model="category.code" ref="code">
                                    <span v-if="index==i" class="text-danger">{{getError('code')}}</span>
                                </td>
                                <td>
                                    <input type="checkbox" @change="update_category(i)"
                                           v-model="category.is_active"></td>

                                <td>

                                    <!--<button v-if="current_category==category.id" @click="update_category(category)"-->
                                    <!--class="btn btn-outline-info shadow">-->
                                    <!--Update-->
                                    <!--</button>-->
                                </td>

                            </tr>


                            <!--<add :path="url.add" v-on:added="newRecordAdded"></add>-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    //    import Add from './Add.vue';
    import forms from '@/forms';
    //    import Edit from './Edit.vue';
    //    import Loader from '../../loader'
    import {Paths} from '../../paths'
    import axios from '@/views/actions/Axios';
    import im from '@/views/layouts/InventoryMenu';

    export default{
        extends: forms,
        components: {axios, im},
        data(){
            return {
                categories: [],
                url: '',
                category: null,
                current_category: -1,
                changed_id: 0,
                interval: null,
                data: {},
                request: 'get',
                trigger: false,
                index: -1,

            }
        }, watch: {
            categories(new_categories){
                this.$store.commit('setCategories', new_categories.filter(function (i) {
                    return i.id > 0;
                }));
            }
        },
        methods: {

            handleCategories: function (data) {
                if (this.url == Paths.category.index) {
                    this.categories = data;
                    this.categories.push(this.empty_model());
                    return;
                }

                this.category_updated(data.data);
            },
            empty_model: function () {

                return {id: 0, name: '', code: '', is_active: true};
            },

            update_category(index){
                this.index = index;
                if (this.categories[index].name.length == 0) {
                    return;
                }
                this.url = Paths.category.add;
                this.request = 'post';
                this.data = this.categories[index];

                this.trigger = true;
            },

            reset_request(){
                this.request = 'get';
                this.index = -1;
                this.url = Paths.category.index;
            },

            category_updated(data){


                if (this.categories[this.index].id == 0) {
                    this.categories.push(this.empty_model());
                }
                this.categories[this.index] = data;

                this.reset_request();
            },

        },
        mounted()
        {
            this.url = Paths.category.index;
            this.trigger = true;
        }
    }
</script>
<style>
    .focus {

    }
</style>